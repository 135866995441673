import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'


const contents = [
    {
        title: '1. 個人情報の取得・利用・提供',
        list: [
            '当社は、上記事業に定めた利用目的の達成に、必要な範囲で適正に個人情報を取扱います。また、個人情報を正確かつ最新の内容に保つよう努めます。',
            '当社は、従業員の個人情報あるいは採用時に取得する個人情報について、利用目的や取扱う内容について、文書あるいはそれに代わる方法で同意を得ます。',
            '当社は、どの様な個人情報を取得する場合にも、事業の内容および規模を考慮した適切な個人情報を取得し、取得時に利用目的を明らかにいたします。',
            '当社は、法令の定めによる場合を除き、保有する個人情報について、利用目的の達成に必要な範囲を超えた取扱いを行わないとともに、目的外の利用を防ぐための措置を講じます。',
            '当社は、本人の承諾無く、第三者に開示・提供することはいたしません。'
        ]
    },
    {
        title: '2. 個人情報の安全対策',
        text: '当社は、個人情報の漏えい、滅失又はき損の防止及び是正に関して、必要かつ適切な安全対策を実施いたします。',
    },
    {
        title: '3. 個人情報に関する苦情および相談について',
        text: '当社は、個人情報の取扱いに関する苦情及び相談については、個人情報に関するお問い合わせ窓口にて受け付け、また個人情報の開示等の求めにも、適切かつ迅速に対応いたします。'
    },
    {
        title: '4. 法令、規範の遵守',
        text: '当社は、個人情報に関する法令、国が定める指針その他規範を遵守いたします。'
    },
    {
        title: '5. 個人情報保護マネジメントシステムの継続的改善',
        text: '当社は、適切な個人情報保護に関する規程、ルール、行動規範を定め、個人情報保護に関するマネジメントシステムを運用します。また、マネジメントシステムが適切に実施できているかを定期的に確認・監査・見直しを行うことにより、継続的に改善いたします。'
    }
];

const personalInfoPolicies = [
    {
        title: '個人情報の利用目的',
        text: '個人情報の利用目的は以下の通りです。利用目的を超えて利用することはありません。',
        list: [
            'ユーザーの個人認証及びユーザー向け本サービスの提供',
            '本サービスの利用に伴う連絡• メールマガジン• DM •各種お知らせ等の配信•送付',
            'ユーザーの承諾•申込みに基づく、本サービス利用企業等への個人情報の提供',
            '属性情報•端末情報•位置情報•行動履歴等に基づく広告•コンテンツ等の配信•表示、本サービスの提供',
            '本サービスの改善•新規サービスの開発およびマーケティング',
            'キャンペーン•アンケート•モニター•取材等の実施',
            '空メール送信者に対するURL情報の配信',
            '本サービスに関するご意見、お問い合わせ、クチコミ投稿内容の確認•回答',
            '利用規約等で禁じている、商用•転用目的での各種申込行為、各種多重申込、権利譲渡、虚偽情報登録などの調査と、それに基づく当該申込内容の詳細確認'
        ]
    },
    {
        title: '個人情報提供の任意性',
        text: '本サービスにおいてそれぞれ必要となる項目を入力いただかない場合は、本サービスを受けられない場合があります。',
    },
    {
        title: '個人情報の第三者への提供',
        text: '当社は、原則として、ユーザー本人の同意を得ずに個人情報を第三者に提供しません。提供先•提供情報内容を特定したうえで、ユーザーの同意を得た場合に限り提供します。ただし、以下の場合は、関係法令に反しない範囲で、ユーザーの同意なく個人情報を提供することがあります。',
        list: [
            'ユーザーが第三者に不利益を及ぼすと判断した場合',
            '公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザー本人の承諾を得ることが困難である場合',
            '国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、ユーザー本人の同意を得る ことによりその事務の遂行に支障を及ぼすおそれがある場合',
            '裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合',
            'ユーザー本人から明示的に第三者への開示または提供を求められた場合',
            '法令により開示または提供が許容されている場合',
            '合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合',
        ]
    },
    {
        title: '開示対象個人情報の開示等の請求に関する手続きについて',
        text: '当社では、本人またはその代理人から、以下の手続に基づき、開示対象個人情報（保有個人データ）の開示、利用目的の通知、訂正・追加・削除、利用の停止、消去及び第三者への提供の停止（以下、「開示等」と言います）の請求を受けた場合、その求めに応じて合理的な範囲で対応致します。',
    },
];

const cInfo = [
    {
        title: '代表取締役',
        text: '弘中英一',
    },
    {
        title: '代表取締役',
        text: '鍵谷篤',
    },
    {
        title: '',
        text: '(附則)',
    },
    {
        title: '2014年03月12日',
        text: '制定•施行',
    },
    {
        title: '2015年01年19日',
        text: '改定',
    },
    {
        title: '2017年03月15日',
        text: '改定',
    },
    {
        title: '2018年10月23日',
        text: '改定',
    },
    {
        title: '2021年01月27日',
        text: '改定',
    },
];

const requestInfo = [
    {
        title: '会社名',
        text: 'マコム・プラニング株式会社',
    },
    {
        title: '個人情報苦情・相談窓口担当',
        text: '柏井 理恵子',
    },
    {
        title: '所在地',
        text: '〒530-0044　大阪府大阪市北区東天満２丁目９番２号AXIS南森町ビル別館５階',
    },
    {
        title: '電話番号',
        text: '06-6881-1096',
    }
];

const docsInfo = [
    {
        title: '請求書',
        list: [
            '開示対象個人情報開示請求書',
            '開示対象個人情報利用目的通知請求書',
            '開示対象個人情報訂正等請求書',
            '開示対象個人情報利用停止等請求書',
            '委任状'
        ]
    },
    {
        title: '本人確認書類',
        list: [
            '運転免許証の写し（※本籍地欄は黒く塗りつぶして下さい）',
            'パスポートの写し',
            '健康保険証の写し',
            '年金手帳の写し',
            '写真付住民基本台帳カードの写し'
        ]
    }
];

const agentInfo = [
    {
        title: '親権者、未成年後見人の場合',
        list: [
            '戸籍謄本または扶養家族が記載された保険証の写し'
        ]
    },
    {
        title: '成年後見人、任意後見人、保佐人、補助人の場合',
        list: [
            '後見登記事項証明書（法務局にて取得のもの）'
        ]
    },
    {
        title: '任意代理人の場合',
        list: [
            '委任状（お客さま等の実印の押印されたもの）',
            'お客さま等の印鑑証明書'
        ]
    }
];

const contact = [
    {
        title: '会社名',
        text: 'マコム・プラニング株式会社',
    },
    {
        title: '個人情報苦情・相談窓口担当',
        text: '清水大介',
    },
    {
        title: '所在地',
        text: '〒530-0044　大阪府大阪市北区東天満２丁目９番２号AXIS南森町ビル別館５階',
    },
    {
        title: '電話番号',
        text: '06-6881-1096（月曜日から金曜日の 10:00～17:00）',
    }
];

const associationInfo = [
    {
        title: '一般財団法人日本情報経済社会推進協会',
        text: '個人情報保護苦情相談室',
    },
    {
        title: '所在地',
        text: '〒106-0032　東京都港区六本木一丁目９番９号六本木ファーストビル内',
    },
    {
        title: '電話番号',
        text: '03-5860-7565, 0120-700-779',
    }
];


const ContentsList = ({ contents }) => {
    return (
        <div className="pb-10 px-4 grid gap-y-10 justify-center">
            {
                contents.map((content, index) => {
                    return (
                        <div key={index} className="grid max-w-3xl gap-y-2">
                            <p className="text-sm text-gray-600">{content.title}</p>
                            <p className="text-sm">{content.text}</p>
                            {content.list &&
                                <ol className="list-decimal list-inside">
                                    {content.list.map((list, index) => {
                                        return (
                                            <li key={index} className="text-sm">{list}</li>
                                        )
                                    }
                                    )}
                                </ol>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
};

const ColumnList = ({ contents }) => {
    return (
        contents.map((content, index) => {
            return (
                <div key={index} className="py-4 grid grid-cols-2 border-b border-gray-800 max-w-3xl">
                    <p className="text-sm text-gray-600">{content.title}</p>
                    <p className="text-sm">{content.text}</p>
                </div>
            )
        })
    )
};

export default function Production() {
    return (
        <>
            <Helmet
                title="情報セキュリティ基本方針 - マコム・プラニング公式サイト"
                description="マコム・プラニングの情報セキュリティ基本方針に関するページです。"
            />
            <Layout>
                <h1 className="text-4xl font-bold text-center pt-32">プライバシーポリシー</h1>

                <div className="py-10">
                    <div className="grid gap-y-2 py-10">
                        <h2 className="text-2xl font-bold text-center">個人情報保護方針</h2>
                        <p className="max-w-2xl mx-auto">当社は、社会に様々な価値を生み出し、また影響を及ぼす一企業として、広告関連事業、福祉施設運営事業及びシステム販売事業を行なう上で、企業や個人の情報を保護する事は重要な社会的責務であると認識しております。個人情報の重要性を、役員及び従業員に日頃から周知徹底し、以下の項目を個人情報保護の基本方針として確実な履行に努めます。</p>
                    </div>
                    <ContentsList contents={contents} />
                </div>

                <div className="pb-10 px-4 grid justify-center">
                    <h2 className="text-2xl font-bold text-center">マコム・プラニング株式会社</h2>
                    <ColumnList contents={cInfo} />
                </div>

                <div className="grid gap-y-2 py-10">
                    <h2 className="text-2xl font-bold text-center">プライバシーマーク認定</h2>
                    <p className="max-w-2xl mx-auto">※当社は「プライバシーマーク」使用許諾事業者として認定されています。 Pマークのロゴ</p>
                </div>

                <div className="grid gap-y-2 py-10">
                    <h2 className="text-2xl font-bold text-center">個人情報の取り扱いについて</h2>
                    <p className="max-w-2xl mx-auto">マコムプラニング株式会社(以下「当社」といいます)は、当プライバシーポリシーを掲示し、当プライバシーポリシーに準拠して提供されるサービス(以下「本サー ビス」といいます)の利用企業•提携企業•団体等(以下「利用企業等」といいます)および本サービスをご利用になる方(以下「ユーザー」といいます)のプライバシーを尊重し、ユーザーの個人情報(以下の定義に従います)の管理に細心の注意を払い、これを取扱うものとします。</p>
                    <p className="max-w-2xl mx-auto">個人情報とは、ユーザー個人に関する情報であって、当該情報を構成する氏名、住所、電話番号、メールアドレス、学校名その他の記述等により当該ユーザーを 識別できるものをいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的にユーザー個人を識別できるものも 個人情報に含まれます。</p>
                    <div className="py-10 px-4 grid gap-y-10 justify-center">
                        <ContentsList contents={personalInfoPolicies} />
                    </div>
                </div>

                <div className="py-10 px-4 grid justify-center">
                    <h2 className="text-2xl font-bold text-center">① 開示等のお申し出先（送付先）</h2>
                    <ColumnList contents={requestInfo} />
                </div>

                <div className="py-10 px-4 grid justify-center max-w-3xl mx-auto">
                    <h2 className="text-2xl font-bold text-center">② 出すべき書面等</h2>
                    <p>開示等の請求を行う場合は、以下の書面が必要です。詳細は①にご連絡下さい。その上で、所定の事項をご記入の上、下記の本人確認書類のいずれか1通を同封して、上記送付先にご郵送下さい。</p>
                    <div className="py-10 px-4 grid gap-y-10 justify-center">
                        <ContentsList contents={docsInfo} />
                    </div>
                </div>

                <div className="py-10 px-4 grid justify-center max-w-3xl mx-auto">
                    <h2 className="text-2xl font-bold text-center">③ 代理人による開示等の請求</h2>
                    <p>開示等の請求者が、ご本人様の法定代理人またはご本人様が委任した代理人である場合は、前項の書類に加えて、下記の書類をご同封下さい。</p>
                    <div className="py-10 px-4 grid gap-y-10 justify-center">
                        <ContentsList contents={agentInfo} />
                    </div>
                </div>

                <div className="py-10 px-4 grid justify-center max-w-3xl mx-auto">
                    <h2 className="text-2xl font-bold text-center">④ 手数料</h2>
                    <p>開示請求及び利用目的の通知請求の場合には、申請1回毎に300円分の手数料をいただきます。 300円分の郵便小為替をご同封下さい。</p>
                </div>

                <div className="py-10 px-4 grid justify-center max-w-3xl mx-auto">
                    <h2 className="text-2xl font-bold text-center">⑤ 当社からの回答方法</h2>
                    <p>ご請求者の記載住所宛に書面にてご回答いたします。</p>
                </div>

                <div className="py-10 px-4 grid justify-center max-w-3xl mx-auto">
                    <h2 className="text-2xl font-bold text-center">⑥ 開示等の請求に伴って取得した個人情報の利用目的</h2>
                    <p>開示等のご請求に伴って当社が取得した個人情報は、開示等の作業に必要な範囲内でのみ取り扱うものとします。 開示等のご請求に際してご提出いただいた書類は、当社からの回答が終了した後、6ヶ月間以内に廃棄いたします。</p>
                </div>

                <div className="py-10 px-4 grid justify-center max-w-3xl mx-auto">
                    <h2 className="text-2xl font-bold text-center">開示対象個人情報の取り扱いに関する苦情の申し出先</h2>
                    <p>当社の個人情報の取扱いに関する苦情につきましては、下記窓口まで、E－Mail、電話又は郵便によりお申し出ください。又、当社は経済産業大臣より認定を受けた、認定個人情報保護団体（一般財団法人日本情報経済社会推進協会）の対象事業者に登録しておりますので、同協会でも当社の個人情報の取扱いに関する苦情や相談を受け付けております。なお、同協会は当社の商品・サービスについての問合せ先ではございませんのでご注意ください。</p>
                </div>

                <div className="py-10 px-4 grid justify-center">
                    <h2 className="text-2xl font-bold text-center">個人情報に関する苦情・相談およびお問い合わせ窓口</h2>
                    <ColumnList contents={contact} />
                </div>

                <div className="py-10 px-4 grid justify-center">
                    <h2 className="text-2xl font-bold text-center">認定個人情報保護団体の名称及び、苦情の解決の申し出先</h2>
                    <ColumnList contents={associationInfo} />
                </div>

            </Layout>
        </>
    )
}
